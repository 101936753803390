import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
// import * as Sentry from '@sentry/angular-ivy';

@Injectable({ providedIn: 'root' })
export abstract class GenericHttpService {

  protected router = inject(Router);

  handleError(error: HttpErrorResponse): void {
    console.error('[GenericHttpService] handleError', error);

    if (this.isBigError(error.status)) {
      console.log('Sentry moet nog geimplementeerd worden');
      //Show Sentry's "Submit crash report" page:
      // const eventId = Sentry.captureException(error);
      // Sentry.showReportDialog({ eventId });

      // Show our error-page after the Sentry page
      this.router.navigate(['error'], { skipLocationChange: true });
    }
  }

  isBigError(status: number): boolean {
    if (status > 399 && status < 500 && status !== 404) {
      return true;
    } else if (status > 499 && status < 600) {
      return true;
    }

    return false;
  }
}
